.App {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App-logo {
  height: 10rem;
  width: 10rem;
  margin-bottom: 2rem;
}

.App-logo-monochrome {
  filter: grayscale(100%);
}

.App-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 31.5rem;
  margin-bottom: 0;
}

.App-form-button {
  margin: 0 1rem;
}

.App-status {
  margin-bottom: 1rem;
}

.App-text {
  width: 100%;
  resize: none;
  min-height: 150px;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  word-wrap: break-word;
  word-break: break-all;
}

.App-hidden {
  display: none;
}

.App-clearHistory {
  margin-bottom: 1rem;
}

.App-history {
  margin-top: 2rem;
  width: 31.5rem;
  margin-bottom: 1rem;
}

.App-li {
  list-style: none;
}

.App-job {
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.App-job-date {
  color: grey;
  white-space: nowrap;
}

.App-job-text {
  margin-right: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
